

/******************************************************************************
START Glitch hello-app default styles

The styles in this section do some minimal CSS resets, set default fonts and
colors, and handle the layout for our footer and "Remix on Glitch" button. If
you're new to CSS they may seem a little complicated, but you can scroll down
to this section's matching END comment to see page-specific styles.
******************************************************************************/
/* Our default values set as CSS variables */
:root {
--color-bg: #CCFFA0;
--color-text-main: #000000;
--color-primary: #AFECE0;
--wrapper-height: 85vh;
--image-max-width: 320px;
--font-family: "HK Grotesk";
--font-family-header: "HK Grotesk";
}

/* Import fonts */
@font-face {
font-family: HK Grotesk;
src: url("https://cdn.glitch.me/605e2a51-d45f-4d87-a285-9410ad350515%2FHKGrotesk-Regular.otf?v=1603136326027")
format("opentype");
}
@font-face {
font-family: HK Grotesk;
font-weight: bold;
src: url("https://cdn.glitch.me/605e2a51-d45f-4d87-a285-9410ad350515%2FHKGrotesk-Bold.otf?v=1603136323437")
format("opentype");
}

/* Our remix on glitch button */
.btn--remix {
font-family: HK Grotesk, serif;
padding: 0.75rem 1rem;
font-size: 1.1rem;
line-height: 1rem;
font-weight: 500;
height: 2.75rem;
align-items: center;
cursor: pointer;
background: #FFFFFF;
border: 1px solid #000000;
box-sizing: border-box;
border-radius: 4px;
text-decoration: none;
color: #000000;
white-space: nowrap;
margin-left: auto;
}
.btn--remix img {
margin-right: 0.5rem;
}
.btn--remix:hover {
background-color: #D0FFF1;
}

/* Navigation grid */
.footer {
display: flex;
justify-content: space-between;
margin: 1rem auto 0;
padding: 1rem 0 0.75rem 0;
width: 100%;
flex-wrap: wrap;
border-top: 4px solid #ffffff;
}

.footer a:not(.btn--remix):link,
a:not(.btn--remix):visited {
font-family: HK Grotesk, serif;
font-style: normal;
font-weight: normal;
font-size: 1.1rem;
color: #000000;
text-decoration: none;
border-style: none;
}
.footer a:hover {
background: var(--color-primary);
}

.footer .links {
padding: 0.5rem 1rem 1.5rem;
white-space: nowrap;
}

.divider {
padding: 0 1rem;
}
/******************************************************************************
END Glitch hello-app default styles
******************************************************************************/
/* Google map */

/* custom control widget */
.custom-control input { width: 145px; }
.custom-control ul {
/*  list-style-type: "\266A"; */
list-style-type: none;
margin: 10px;
padding: 0 5px;
}
.custom-control ul li::before {
/*  content: "\266A"; */
content: "\1F3AF";
color:red;
font-weight:bold;
padding: 0 5px 0 0;
}

/* input form below map */
.search-container {
/*  position: absolute; */
max-width:333px;
top: 10px;
left: 10px;
z-index: 1;
background-color: white;
padding: 10px;
border: 1px solid #cccccc;
display: flex;
align-items: center;
box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
margin: 5px 0px 20px;
}

.search-container input {
margin-right: 10px;
padding: 5px;
border: 1px solid #cccccc;
}

.search-container button {
padding: 5px 10px;
background-color: #007BFF;
color: white;
border: none;
cursor: pointer;
}

/* radius tally indicator */
.targeted ::before {
/*  content: "\266A"; */
content: "\2705";
color:green;
font-weight:bold;
padding: 0 5px 0 0;
}

.map-radius-tally {
content: "\1F3AF";
/*  color:green;
font-weight:bold;
padding: 0 5px 0 0; */

}
.map-radius-tally ::before {
content: "\1F3AF";
}

.el-google-map .map-radius-tally ::before  {
content: "\2705";
padding-right: 5px;
}

.radius-tally {
margin: 10px 0;
}

/* tally below map */
.tally-count-div {
/*  max-width:333px; */
background-color: white;
border: 1px solid #cccccc;
padding: 10px;
margin: 10px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
/* text-align: center; */
}

.tally-count-div-inner {
padding:0px 10px;
}

/* .tally-count-div-inner button {
padding: 3px 5px;
margin: 5px;
background-color: #007bff;
color: white;
border: 1px solid #cccccc;
cursor: pointer;
border-radius:1px;
} */

.tally-count-div h3 {
margin: 0 0 10px;
}

.tally-count-div h4 {
font-weight: bold;
}

.tally-count-div ul {
list-style-type: "\1F3AF";
margin: 10px;
padding: 0 5px;
/* display: flex;
flex-direction: column;
flex-wrap: wrap;
height:500px; */
}
.tally-count-div .citytally ul {
list-style-type: "\1F6A9";
}

.tally-count-div li {
/*  font-weight: bold; */
margin: 5px;
padding: 2px 5px;
}
/* Target even (alternate) list items and give them a different background color */
.tally-count-div li:nth-child(even) {
background-color: #f2f2f2; /* Change this color as needed */
}
/* Target even (alternate) list items and give them a different background color */
.custom-control li:nth-child(even) {
background-color: #f2f2f2; /* Change this color as needed */
}

.fancount { border: 1px solid #cccccc; border-radius: 5px; color: green; padding: 5px; }
.high-fives { padding: 0px 5px; }

.radiuscount {
border: 2px dotted #559944;
border-radius: 50%;
color: red;
/* width: 40px; */
height: 40px;
display: inline-block;
text-align: center;
vertical-align: middle;
padding: 1px 15px;
}

.radius-marker-styling {
cursor: sw-resize !important;
background-color:#ffffff;
border: 1px solid #cccccc;
border: 5px;
padding: 5px;
position: relative;
top: -20px;
left: -0px;
}
.radius-marker-styling:hover {
cursor: sw-resize !important;
}

.tabletop {
font-weight:bold;
margin: 5px 0;
border-bottom: 1px dashed #cccccc;
}
.tablezip {
text-align:left;
float:left;
width:200px;
}
.tabletop .tablezip {
margin: 0px 25px 0px 0px;
}

.tablecount {
text-align:left;
float:left;
border-left: 1px solid #cccccc;
padding-left: 5px;
width:100px;
}

.tabletop div:hover {
background-color: #daffda;
cursor: pointer;
}

.tablenearest {
text-align:left;
float:left;
border-left: 1px solid #cccccc;
padding-left: 5px;
width:200px;
}

.tabledistance {
border-left: 1px solid #cccccc;
text-align:right;
width:200px;
float:right;
}
.tabletop .tabledistance {
margin:0 25px 0 0;
}

.tablesort {
text-align:left;
float:right;
padding: 0 0 5px 10px;
/*  width:200px; */
}
.citytable .tablesort {
float:none;
}

.h4nopad {padding-bottom:0;margin-bottom:0;}

.clear {
display:block;
clear:both;
}

/* Style the container for the label and select */
.sort-container {
display: flex; /* Use flexbox to align items in a row */
align-items: center; /* Vertically center the items */
}

/* Style the label */
.sort-container label {
margin-right: 10px; /* Add some space to the right of the label */
font-weight: bold; /* Make the label bold */
}

/* Style the select element */
.sort-container select {
padding: 5px; /* Add some padding to the select */
border: 1px solid #cccccc; /* Add a border */
border-radius: 4px; /* Add rounded corners */
font-size: 14px; /* Adjust the font size */
min-width: 100px;
}

/* Style the select options */
.sort-container select option {
font-size: 14px; /* Adjust the font size */
}

/* Style the select when it's focused (clicked) */
.sort-container select:focus {
outline: none; /* Remove the default outline */
border-color: #007BFF; /* Add a different border color when focused */
}

.bestmap { background-color: #9900ff; color: #ffffff; padding: 3px !important; }

.spacer { display:block; height: 40px; }